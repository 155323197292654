@media print {
  .print-ignore {
    display: none;
  }

  main {
    max-width: 140ch;
  }

  img {
    aspect-ratio: 1;
    object-fit: cover;
    max-height: var(--img-min-height);
  }

  a {
    border-bottom: none;
  }

  iframe {
    display: none;
  }

  .page-breaks {
    padding-top: calc(var(--d) * 5);
    break-before: page;
  }

  .non-grid {
    max-width: 140ch;
  }

  .non-grid:has(iframe) {
    display: none;
  }
}
/*# sourceMappingURL=read-fieldnotes.36523c9f.css.map */
